<template>

<div class="relative">
    <div class="prediction-bg">
        <img class="w-100 h-100" src="../../../static/images/background_event.png">
    </div>
    <div class="container display-flex flex-col has-space mt-m">
        <div class="tc font-m mb-05rem bold" v-if="isMobile">{{contestInfo.contestDescription}}</div>
        <!-- banner -->
        <div class="mb-2rem">
            <div class="prediction-banner__contest show scroll-from-top">
                <img class="img-cover scale-fix" :alt="contestInfo.contestBanner" :src="`${s3ImgUrl}${contestInfo.contestBanner}`">
                <div class="prediction-banner__contest-overlay">
                    <!-- <span v-html="contestInfo.contestDescription"></span> -->
                </div>
            </div>
            <div class="prediction-banner__prize">
                <!-- grand prize banner -->
                <div class="prediction-banner__prize-card scroll-from-bottom">
                    <img class="img-cover" :src="`${s3ImgUrl}${contestInfo.grandPrizeImage}`">
                    <div class="prediction-banner__prize-card__overlay">
                        <span v-html="contestInfo.grandPrizeHover"></span>
                    </div>
                </div>
            </div>
        </div>

        <!-- countdown -->
        <div class="prediction-countdown__wrapper scroll-from-top">
            <div class="display-flex-center">
                <div class="prediction-countdown__title">
                    <span v-if="!isEventEnded" >{{$t(countDownPrefix)}}</span>
                        <div v-else>{{$t('EVENT_ENDED')}}</div>
                    </div>
                <div v-if="!isEventEnded" class="prediction-countdown">
                    <div class="prediction-countdown__time" v-if="eventTime.days !== '00'">
                        <span class="prediction-countdown__time-time">{{eventTime.days}}</span>
                        <span class="prediction-countdown__time-day">{{$t('DAYS')}}</span>
                    </div>
                    <div class="prediction-countdown__time-seperator"  v-if="eventTime.days !== '00'"><span>:</span></div>

                    <div class="prediction-countdown__time">
                        <span class="prediction-countdown__time-time">{{eventTime.hours}}</span>
                        <span class="prediction-countdown__time-day">{{$t('HRS')}}</span>
                    </div>
                    <div class="prediction-countdown__time-seperator"><span>:</span></div>

                    <div class="prediction-countdown__time">
                        <span class="prediction-countdown__time-time">{{eventTime.min}}</span>
                        <span class="prediction-countdown__time-day">{{$t('MINS')}}</span>
                    </div>
                    <div class="prediction-countdown__time-seperator"  v-if="eventTime.days === '00'"><span>:</span></div>
                    <div class="prediction-countdown__time" v-if="eventTime.days === '00'">
                        <span class="prediction-countdown__time-time">{{eventTime.sec}}</span>
                        <span class="prediction-countdown__time-day">{{$t('SECS')}}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Join event -->
        <div class="prediction-team__wrapper scroll-from-top" >
            <h3 class="mb-1rem tc">
                <span v-if="isJoinedEvent">{{$t('YOU_HAVE_PARTICIPATE_THIS_EVENT')}}</span>
                <span v-else>{{$t('PARTICIPATE_THIS_EVENT')}}</span>               
            </h3>
            <SocialMediaAndTermCondition  @changeTickEvent2="changeTickEvent2($event)" :isTick="isJoinedEvent || storeDataPath.isTick"></SocialMediaAndTermCondition>
            <div class="prediction-team__btn-submit">
                <router-link :to="{ name: 'myReferralCode'}"   v-if="isJoinedEvent"  class="btn btn-primary w-100 tc block border-box enable">           
                    <span class="c-black">{{$t('REFER_YOUR_FRIEND')}}</span>             
                </router-link>
                 <button  v-else class="btn btn-primary disable w-100"  @click="handleBtnSubmit" :class="{'enable':isBtnParticipateEnable}">
                    <span>{{$t('PARTICIPATE')}}</span>
                </button>
            </div>
            <div class="prediction-team__term_condition btn--underline" @click="showModalTermCondition()">{{$t('TERMS_&_CONDITIONS')}}</div>
        </div>

        <div class="display-flex-center scroll-from-top">
            <h4 class="prediction-record">{{$t('LEADERBOARD')}}</h4>
        </div>

        <!-- tabs -->
        <div class="mb-1rem display-flex-center md:flex-start scroll-from-top">
            <div class="tabs" :class="{'selected': o.selected}" v-for="(o,index) in tabs" :key="index" @click="handleTabs(o)">
                <span class="tabs-name">{{$t(o.name)}}</span>
                <span class="tabs-line"></span>
            </div>       
        </div>
        
        <!-- table -->
        <div v-if="currentTabs === 1" class="prediction-table scroll-from-top" >
            <div class="prediction-table__header">
                <div class="prediction-table__header-title tc"><span>{{$t('NICKNAME')}}</span></div>
                <div class="prediction-table__header-title tc"><span >{{$t('REFERRALS')}}</span></div>
            </div>
            <div class="prediction-table__body">
                <li v-show="timeType !== 3" class="prediction-table__body-list" v-for="(o, index) in latestReferralList" :key="index">
                    <span class="flex-1 tc ">{{o.displayName}}</span>
                    <span class="flex-1 tc ">{{o.numOfReferral}}</span>
                    <div class="prediction-table__body-list__divider"></div>
                </li>       
               <div v-show="timeType === 3" class="prediction-table__no-data">{{$t('NO_REFERRAL')}}</div>       
            </div>
            <div class="prediction-table__footer">

            </div>
        </div>
        <div v-if="currentTabs === 2" class="prediction-table">
            <div class="prediction-table__header">
                <div class="prediction-table__header-title tc"><span>{{$t('REFERRALS')}}</span></div>
            </div>
            <div class="prediction-table__body">
                <li v-show=" timeType !== 3" class="prediction-table__body-list" v-for="(o, index) in myReferralList" :key="index">
                    <span class="flex-1 tc ">{{o.numOfReferral}}</span>
                    <div class="prediction-table__body-list__divider"></div>
                </li>       
                <li v-show="timeType === 3" class="prediction-table__body-list">
                     <span class="flex-1 tc">0</span>
                    <div class="prediction-table__body-list__divider"></div>
                </li> 

            </div>
            <div class="prediction-table__footer">
                
            </div>
        </div>
    </div>
</div>
  
    <!-- sign up info -->
    <ModalPopup ref="modalPopupSignupRef" >
        <div class="display-flex-align-center flex-col modal-confirmation__body">
            <div class="mb-15rem font-m tc">{{$t('EVENT_PREDICTION_SUBMIT_INFO')}}</div>
            <div v-if="isMobile" class="w-100">
                <router-link :to="{ name: 'signup' , query: { callBackUrl: currentPathName }}"> <button class="btn w-100 mb-1rem bg-white">{{$t('SIGNUP')}}</button></router-link>
                 <router-link :to="{ name: 'signin' , query: { callBackUrl: currentPathName }}"><button class="btn btn-outline w-100">{{$t('LOGIN')}}</button></router-link>
            </div>
            <div v-else class="w-100">
                <button class="btn w-100 mb-1rem bg-white" @click="handleAuthentication('signUp')">{{$t('SIGNUP')}}</button>
                <button class="btn btn-outline w-100" @click="handleAuthentication('signIn')">{{$t('LOGIN')}}</button>
            </div>
        </div>
    </ModalPopup>

    <!-- confirm participate -->
    <ModalPopup ref="modalPopupConfirmParticipateRef" >
        <div class="display-flex-align-center flex-col modal-confirmation__body">
            <div class="font-m mb-15rem tc">{{$t('CONFIRMATION_TO_PARTICIPATE')}}</div>
            <div class="w-100">            
                 <button class="btn w-100"  @click="handleParticipate">{{$t('CONFIRM')}}</button>
            </div>
        </div>
    </ModalPopup>

    <PopUpSignIndex ref="modalPopupSignInRef" @loginSucuessEvent="loginSucuessEvent"></PopUpSignIndex>

    <!-- term and condition desktop-->
   <ModalPopup ref="modalPopupRef" >
        <h3 class="tc mt-15rem mb-15rem">{{$t('TERMS_&_CONDITIONS')}}</h3>
        <perfect-scrollbar>
            <div class="term-body" v-html="contestInfo.tnc"></div>
        </perfect-scrollbar>
    </ModalPopup>
    
    <!-- term and condition mobile-->
    <MobileSlide ref="mobileSlideRef" :title = "'TERMS_&_CONDITIONS'">
        <div class="pb-1rem term-body"  v-html="contestInfo.tnc"></div>
    </MobileSlide>

</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import moment from 'moment'

import SocialMediaAndTermCondition from '@/components/event/SocialMediaAndTermCondition.vue'
import ModalPopup from '@/components/modal/ModalPopup.vue'
import MobileSlide from '@/components/modal/MobileSlide.vue'
import PopUpSignIndex from '@/components/user/PopUpSignInIndex.vue'

import config from '@/js/config.js'
export default {
    components:{
        ModalPopup,
        MobileSlide,
        SocialMediaAndTermCondition,
        PopUpSignIndex
    },

    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            currentPathName: '',

            countDownPrefix: "",
            countDownInterval: null,

            eventTime: {
                days: '',
                hours: '',
                min: '',
                sec: ''
            },

            tabs: [
                {
                    name: 'LATEST',
                    id: 1,
                    selected: false
                },
                {
                    name: 'MY_REFERRALS',
                    id: 2,
                    selected: false
                },
            ],
       
            //3 status
            isEventEnded: false,
            isJoinedEvent: false,
            isBtnParticipateEnable: false,
  
            contestInfo: {},
     
            latestReferralList: [],
            myReferralList: [],

            mobile: {
                isShowTermCondition: false
            },

            isLoginFromPopUp: false,
            redirectFrom:'',
            storeDataPath: {
                isTick: false
            },
        }
    },
    
    watch:{
        isLogin: {
            deep: true,
            handler(newVal, preVal) {
                if (newVal ===  false) {
                    //if is logout refresh the page
                    this.init();
                }         
            }
        },
        currentLocale: {
            deep: true,
            handler(prev, nesw) {
              this.init(); 
            }
        },
   },
    computed: {
        ...mapGetters([              
                'isMobile',
                'currentLocale',
                'isLogin'
           ]),
      
    },
    mounted() {
        this.init();
        window.sr = ScrollReveal();
        this.$nextTick(() => {
            sr.reveal('.scroll-from-left', {
                origin: 'left' ,
                duration: 1000,
                easing: 'ease-out' ,
                distance: '500px',
                delay: 100,
                        
            });
                sr.reveal('.scroll-from-right', {
                    origin: 'right' ,
                    duration: 1000,
                    easing: 'ease-out' ,
                    distance: '200px',
                    delay: 100     ,
                    
                });
                sr.reveal('.scroll-from-bottom', {    
                    duration: 1000,
                    distance: '50px',
                    delay: 100              
                });

                sr.reveal('.scroll-from-top', {    
                    origin: 'top' ,
                    duration: 1000,
                    distance: '200px',
                    delay: 100              
                });
                sr.reveal('.scroll-from-opacity', {    
                    distance: '200px',
                        opacity: 0.8,
                        duration: 1000,
                });
                sr.reveal('.scroll-from-scaleUp', {    
                    distance: '200px',
                scale: 0.85,
                duration: 1000,
                });
        });
    },
    beforeUnmount() {
        clearInterval(this.countDownInterval);
    },
    methods: {
        ...mapActions([
            "getContestInfo",
            "postParticipate",
            "postListParticipate",
            "postMyParticipate",
            "popUpSignInModuleEvent",
            "navigationMenuEvent",
            "getContentList",
            "toastrChangeEvent"

        ]),

        init() {
                
            //get contest id
            this.contestId = this.$route.params.contestId;
            this.currentPathName = window.location.pathname;

            this.getContestInfoData();
            this.getMyParticipateData();
            this.handleTabs(this.tabs[0]);
        },

        eventEnded() {
            this.isEventEnded = true;
            this.isBtnParticipateEnable = false;
        },

        async getContestInfoData() {
            let params = {
               contestId: this.contestId,
               language: this.currentLocale
            }
            const result = await this.getContestInfo(params);
           

            if (result.result === null) {
                this.$router.push(`/${this.currentLocale}`); 
                return;
            } else {
                this.contestInfo = result.result;
            }
           

            this.timeType = this.contestInfo.timeType;
            
            if (this.timeType === 2) {
                //ONGOING
                this.countDownPrefix = "ENDING_IN";  
                
                this.updateContestInfoDate(result.result.endDate);
            } else if (this.timeType === 3) {
                //UPCOMING
                this.countDownPrefix = "STARTING_IN";
               
                this.updateContestInfoDate(result.result.startDate);
            } else {
                this.eventEnded()
                this.countDownPrefix = "EVENT_ENDED"          
            }

        },

     
        //countdown contest end date 
        updateContestInfoDate(endDate) {
                         var t = new Date();
            t.setSeconds(t.getSeconds() + 10);  
                    let countDownDate = new Date(endDate);

                    // Get today's date and time
                     let now = moment.utc().valueOf();

                    // Find the distance between now and the count down date
                    let distance = countDownDate - now;
                    
                    if (distance < 0) {
                        this.eventEnded()
                        return;
                    }
                    // Time calculations for days, hours, minutes and seconds
                    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    // Display the result in the element with id="demo"
                    this.eventTime.days = days<10 ? '0'+days: days;
                    this.eventTime.hours =  hours<10 ? '0'+hours: hours;
                    this.eventTime.min = minutes<10 ? '0'+minutes: minutes;
                    this.eventTime.sec = seconds<10 ? '0'+seconds: seconds;
                    // Update the count down every 1 second

                   this.countDownInterval = setInterval(() => {
                            // Get today's date and time
                            let now = moment.utc().valueOf();

                            // Find the distance between now and the count down date
                            let distance = countDownDate - now;

                            // Time calculations for days, hours, minutes and seconds
                            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                            // Display the result in the element with id="demo"
                            this.eventTime.days = days<10 ? '0'+days: days;
                            this.eventTime.hours =  hours<10 ? '0'+hours: hours;
                            this.eventTime.min = minutes<10 ? '0'+minutes: minutes;
                            this.eventTime.sec = seconds<10 ? '0'+seconds: seconds;
                            // If the count down is finished, write some text
                            if (distance <= 1) {
                                if (this.timeType === 3) {
                                   //upcoming
                                    this.init();
                                } else if (this.timeType === 2) {
                                    //ongoing
                                   this.eventEnded()
                                } else {
                                    //past
                                     this.eventEnded();
                                }
                                clearInterval(this.countDownInterval);
                            }
                    }, 1000);
            
         
        },
        
        async getTeamListByContestIdData() {
            let params = {
                contestId: this.contestId
            }
            const result = await this.getTeamListByContestId(params);

            result.result.forEach(x => {
                x.selected = false;
                x.isShow = true;
            });

            this.modalTeamList = result.result;
        },

        //show modal pop up term & condition
        showModalTermCondition() {
            if (this.isMobile) {
                this.$refs.mobileSlideRef.handleOpen();
            } else {
                 this.$refs.modalPopupRef.handleOpen();
            }
        },

        loginSucuessEvent(data) {
            this.$refs.modalPopupSignInRef.handleClose();
            this.redirectFrom = data;
            this.init(); 
        },

        handleAuthentication(type) {
            this.popUpSignInModuleEvent(type)
            this.$refs.modalPopupSignInRef.handleOpen();
            this.$refs.modalPopupSignupRef.handleClose(); 
        },

        handleBtnSubmit() {
            if (!this.isLogin) {
                //sign up confirmation
                this.$refs.modalPopupSignupRef.handleOpen();  
            } else {
                //participate confimation
                this.$refs.modalPopupConfirmParticipateRef.handleOpen();
            }
        },

        async getContentListData() {
            let params = {
                timeType: 2,
                language: this.currentLocale
            }
         
            const result = await this.getContentList(params);

            let returnResult = result.result;

            this.navigationMenuEvent(returnResult);
        },
       async handleParticipate() {
          
           if (!this.isBtnParticipateEnable) {
               return;
           }

            let redirectFrom = this.redirectFrom
            this.$refs.modalPopupConfirmParticipateRef.handleClose();

            let params = {
                contestId: this.contestId
            }

           const result = await this.postParticipate(params);

            if (result.result.businessCode === 0) {

                this.isLoginFromPopUp = false;
                this.getContentListData();

                this.isJoinedEvent = true;
                this.getListParticipateData();
                this.getMyParticipateData(true);

                 let toastrMsg = "PARTICIPATE_SUCCESSFULLY";
                    if (redirectFrom === 'signIn') {
                        toastrMsg = 'REFERRAL_SUBMITTED_SUCCESSFULLY_POPUP_SIGN_IN';
                    } else if (redirectFrom === 'signUp') {
                        toastrMsg = 'REFERRAL_SUBMITTED_SUCCESSFULLY_POPUP_SIGN_UP';
                    } else if (redirectFrom === 'forgetPassword') {
                        toastrMsg = 'REFERRAL_SUBMITTED_SUCCESSFULLY_POPUP_PASSWORD_CHANGE';
                    }
                     let paramsToastr={
                        isOpen:true,
                        msg: toastrMsg,
                        type:''       
                    }
                    this.toastrChangeEvent(paramsToastr);
                // this.$root.toastrMsg = toastrMsg;
                // this.$root.$refs.toastrRef.handleOpen(); 
            } else {
                let paramsToastr={
                        isOpen:true,
                        msg:  result.result.message,
                        type:'info'       
                    }
                    this.toastrChangeEvent(paramsToastr);
                // this.$root.toastrMsg = result.result.message;
                // this.$root.toastrType = 'info';
                // this.$root.$refs.toastrRef.handleOpen();
                //this.init();
            }
        },

        async getListParticipateData() {
            let params = {
                contestId: this.contestId,
            }

            const result = await this.postListParticipate(params);
          
            this.latestReferralList = result.result.data.participants;

        },

        async getMyParticipateData(isOnlyGetMyParticipateData) {
            let params = {
                contestId: this.contestId,
            }

            const result = await this.postMyParticipate(params);

            if (result.result !== null) {
                 let object = {
                    numOfReferral: 0
                }
                
                if (result.result.data === null) {                  
                     this.myReferralList[0] = object;
                     this.isJoinedEvent = false; 
                } else {
                    this.myReferralList = result.result.data.participants;
                    if (this.myReferralList.length>0) {
                        this.isJoinedEvent = true;
                    } else {
                        this.myReferralList[0] = object;
                        this.isJoinedEvent = false; 
                    }

                    if (!isOnlyGetMyParticipateData) {
                        if (this.isJoinedEvent === false) {
                            
                            let routeQuery = this.$route.query;
                            let queryObject = {};
                       
                            if (Object.keys(routeQuery).length !== 0 && routeQuery.constructor === Object) {
                                queryObject = routeQuery;                      
                            } else if (typeof this.storeDataPath.isTick !== 'undefined') {
                                queryObject = this.storeDataPath;
                            } 
                            if (Object.keys(queryObject).length !== 0) {
                                if (typeof queryObject.redirectFrom !== 'undefined') {
                                    this.redirectFrom = queryObject.redirectFrom;
                                }

                                this.storeDataPath.isTick = queryObject.isTick;
                                this.isBtnParticipateEnable = this.storeDataPath.isTick;
                                
                                this.handleParticipate()
                            }
                        }                      
                    }
                } 
            }
        },

        handleTabs(o) {
            this.tabs.forEach(x => {
                x.selected = false;
            });
            o.selected = true;
            this.currentTabs = o.id
            
            switch (o.id) {
                case 1:
                    this.getListParticipateData();
                    break;
                case 2:
                    this.getMyParticipateData();
                    break;
            }
        },

        changeTickEvent2(result) {
           if (this.timeType === 3 || this.isEventEnded) {
                //UPCOMING or event ended
                this.isBtnParticipateEnable = false;
            } else {
                this.isBtnParticipateEnable = result;
            }

            this.currentPathName = `${this.currentPathName}?isTick=${this.isBtnParticipateEnable}`
             this.storeDataPath = {
                 isTick: this.isBtnParticipateEnable
             }
        },

    },
}
</script>

<style scoped>
.prediction-bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.prediction-banner__contest{
    margin-bottom: 1rem;
    height: 11.25rem;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.prediction-banner__prize{
 
    display: grid;
    grid-template-columns: repeat(1,1fr);
	column-gap: 1rem;
    height: 7.5rem;
}
.prediction-banner__contest.show .prediction-banner__contest-overlay{
    transform: translate(0,0);
}
.prediction-banner__contest img{
    transition: all .3s;
}
.prediction-banner__contest.show img{
     transform: scale(1.2);
}
.prediction-banner__contest-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 0 0 1.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    transform: translate(0,100%);
    transition: all .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:3.125rem;
    text-align: center;
}
.prediction-banner__prize-card{
    height: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}
.prediction-banner__prize-card__overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 0 0 1.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    transform: translate(0,100%);
    transition: all .3s;
    background-color: rgb(0 0 0 / 72%);
}
.prediction-banner__prize-card img{
     transition: all .3s;
}
.prediction-banner__prize-card:hover img{
    transform: scale(1.2);
}
.prediction-banner__prize-card:hover .prediction-banner__prize-card__overlay{
    transform: translate(0,0);
}
.prediction-countdown__wrapper{
    margin-bottom: 2.25rem;
}
.prediction-countdown__title{
    font-size: 1.125rem;
 
}
.prediction-countdown{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}
.prediction-countdown__time{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-theme);
    padding: 0.5rem;
    border-radius: 0.5rem;
}
.prediction-countdown__time-seperator{
    margin: 0 0.5rem;
}
.prediction-countdown__time-time{
    font-size: 1.375rem;
    font-weight: 700;
}
.prediction-countdown__time-day{
    font-size: 0.6875rem
}
.prediction-entry__title{
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0 0 0.5rem 0;
}
.prediction-entry__desc{
    font-size: 0.6875rem;
    color: var(--color-theme-2);
    margin-bottom: 1.1875rem;
}
.prediction-team__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-theme);
    width: 49.625rem;
    padding: 1rem;
    border-radius: 1rem;
    margin: 0 auto;
    margin-bottom: 1.9375rem;
    box-sizing: border-box;
}
.prediction-team{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.prediction-team__name{
    margin-bottom: .5rem;
    font-size: 0.875rem;
    font-weight: 700;
}
.prediction-team__choose{
    background-color: black;
    border-radius: 0.5rem;
    width: 6.25rem;
    height: 6.25rem;
     display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .5rem;
    padding: 1rem;
    box-sizing: border-box;
}
.prediction-team__choose:hover{
    background-color: var(--color-blue-20);
}
.prediction-team__desc{
    font-size: 0.6875rem;
}
.prediction-team__container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;
}
.prediction-team__score-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.9rem;
}
.prediction-team__score{
    border-radius: 0.5rem;
    background-color: black;
    width: 4.125rem;
    height: 4.125rem;
    margin: 0 1.875rem;
    overflow: hidden;
}
.prediction-team__vs{
    font-size: 1.375rem;
    font-weight: bold;
}
.prediction-team__score input{
    width: inherit;
    height: inherit;
    background-color: transparent;
    color: white;
    text-align: center;
    border: none;
    font-weight: 700;
    font-size: 1.5rem;
}
.prediction-team__score input::placeholder{
    color: white;
    font-weight: 700;
}
.prediction-team__btn-submit{
    width: 21.25rem;
    margin-bottom: 1.25rem;
    cursor: pointer;
}
.prediction-team__term_condition{
    color: var(--color-blue-20);
    /* text-decoration: underline; */
    cursor: pointer;
}

/* table */
.prediction-table{
    width: 49.625rem;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 0 auto 5rem;
}
.prediction-table__header{
    background-color: var(--color-theme);
    padding: 0.625rem;
    display: flex;
}
.prediction-table__header-title{
    flex: 1;
    font-size: 0.875rem;
}
.prediction-table__header-title-name{
    padding-left: 5rem;
}
.prediction-table__body{
    font-size: 0.6875rem;
    min-height: 20.5rem;
    background-color: rgb(32 32 32 / 60%);
}
.prediction-table__body-list{
    display: flex;
    padding: 0.65625rem;
    position: relative;
    justify-content: center;
}
.prediction-table__body-list__divider{
    position: absolute;
    bottom: 0;
    width: 80%;
    height: .08rem;
    background-color: var(--color-grey-60);
}
.prediction-table__body-list__result{
    margin-right: 2rem;
    display: flex;
}
.prediction-table__body-list__result-name{
    width: 5rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
    text-align: right;
}
.prediction-table__body-list__result-score-2{
    width: 1.5rem;
    display: block;
}

.prediction-table__footer{
    background-color: var(--color-theme);
    padding: 0.625rem;
    display: flex;
    min-height: 1rem;
}
.prediction-table__no-data{
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}
.term-body{
    max-height: 15.5rem;
    font-size: 0.875rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
    color: var(--color-grey-light);
}

.prediction-modal__team-search__wrapper{
    background-color: var(--color-theme-2);
    border-radius: 1rem;
    box-sizing: border-box;
    padding: 0.1rem;
    position: relative;
    margin-bottom: 1.5rem;
    overflow: hidden;
}
.prediction-modal__team-search__input{
    width: 100%;
    border: none;
    background-color: transparent;
    padding-left: 2.5rem;
    height: 2.1rem;
    color: white;
}
.prediction-modal__team-search__input::placeholder{
    color: white;
}
.prediction-modal__team-search__input:focus{
    outline: none;
}
.prediction-modal__team-search__icon{
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
    position: absolute;
    top: 0.7rem;
    left: 1rem;
    pointer-events: none;
}
.prediction-modal__team-icon__wrapper{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
    height: 12.5rem;
    margin-bottom: 1rem;
    /* overflow-y: auto; */
    text-align: center;
    grid-template-rows:  repeat(2,1fr);
}
.prediction-modal__team-icon__container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
     cursor: pointer;
}
.prediction-modal__team-name{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.6875rem;
    height: 2rem;
}
.prediction-modal__team-icon{
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: .5rem;
    padding: 0.4rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 0.15rem solid transparent;
}
.prediction-modal__team-icon.selected{
    border-color: var(--color-hover);
    background-color: var(--color-grey-60);
}
.prediction-modal__team-icon__container:hover .prediction-modal__team-icon{
    border-color: var(--color-hover);
    background-color: var(--color-grey-60);
}

  @media (max-width: 768px){
         .prediction-banner__contest-overlay{
            font-size: 1.375rem;
        }
        .prediction-banner__prize-card__overlay{
            font-size: 0.6875rem;
        }
         .prediction-team__wrapper{
            width: 100%;
            margin-bottom: 1.5rem;
        }
        .prediction-table{
             width: 100%;
        }
          .prediction-table__header-title-name{
            padding-left: 5.5rem;
        }
        .prediction-team__btn-submit{
            width: 100%;
        }
        .prediction-team__score input{
            font-size: 1.2rem;
        }
        /* .prediction-table__header-title{
            text-align: left;
            padding-left: 2rem;
        } */
    }

</style>
<style>
.ps__rail-y{
    width: 6px !important;
    opacity: 1 !important;
    background-color: #212B30 !important;
    right: 0 !important;
    border-radius: 1rem;
}
.ps__thumb-y{
    width: 7px !important;
    border-radius: 1rem;
    background-color: var(--color-theme-2) !important;
}
.ps__rail-x{
    display: none !important;
}
</style>